import { Injectable } from '@angular/core';
import { StatusToPhase } from 'src/assets/enums/statusEnum';
import { Product} from 'src/models/Product.model';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class BurrService {
  constructor(public storage: Storage) {
  }

  async setMetadata(prodotto: Product, phase: StatusToPhase): Promise<Product> {
     
     
    return prodotto
  }
}
