import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { User } from "src/models/user.model";
import { Role } from "src/models/role.model";
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { AlertController, MenuController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class UserService {
    folder: any;
    handleError: any;

    constructor(private httpClient: HttpClient,
        private router: Router,
        private alertController: AlertController,) { }

    getAllUser() {
        return this.httpClient.get(environment.baseApiUrl + 'user');
    }

    getAllRoles() {
        return this.httpClient.get(environment.baseApiUrl + 'role');
    }

    getRoleById(id: string): Observable<Role> {
        return this.httpClient.get<Role>(environment.baseApiUrl + 'role/role' + id).pipe(
            tap(_ => console.log(`fetched role id=${id}`))
        );
    }

    getRoleBy(id: number): Observable<Role> {
        return this.httpClient.get<Role>(environment.baseApiUrl + 'role/' + id).pipe(
            tap(_ => console.log(`fetched role id=${id}`))
        );
    }

    getRoleByAddress(address: string): Observable<string> {
        return this.httpClient.get<string>(environment.baseApiUrl + 'oti721/accessControl/read/getRole/' + address).pipe(
            tap(_ => console.log(`fetched role id=${address}`))
        );
    }
    
    getUser(id: string) {
        return this.httpClient.get<User>(environment.baseApiUrl + 'user/user/' + id).pipe(
            tap(_ => console.log(`fetched user id=${id}`))
        );
    }

    getUserByEmail(email: string) {
        return this.httpClient.get<User>(environment.baseApiUrl + 'auth/profile').pipe(
            tap(_ => console.log(`fetched user email=${email}`))
        );
    }

    updateUser(user: User): Observable<User> {
        return this.httpClient.put<User>(environment.baseApiUrl + 'user/update/' + user._id, user)
            .pipe(
                tap(() => console.log('updated user: ' + user._id)),
                map(() => user),
                catchError(this.handleError)
            );
    }


    createUser(user: User) {
        return this.httpClient.post(environment.baseApiUrl + 'user/create', user)
            .pipe(
                tap(data => console.log('created user: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

}