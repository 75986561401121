import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User } from "src/models/user.model";
import { UserService } from "../user/user.service";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    handleError: any;
    constructor(private httpClient: HttpClient,
        private userService: UserService) { }

    login(user: User) {
        return this.httpClient.post(environment.baseApiUrl + 'auth/login', user);
    }

    role(address: string) {
        return this.httpClient.get(environment.baseApiUrl + 'oti721/accessControl/read/getRole/'+address);
    }

    resetPassword(credentials: any) {
        return this.httpClient.post(environment.baseApiUrl + 'auth/email/reset-password', credentials)
            .pipe(
                tap(() => console.log('password updated')),
                catchError(this.handleError)
            );
    }

    forgetPassword(email: string, language: string) {
        return this.httpClient.get(environment.baseApiUrl + 'auth/email/forgot-password/' + email + '/' + language)
            .pipe(
                tap(() => console.log('email sent')),
                catchError(this.handleError)
            );
    }


}