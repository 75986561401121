import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { RoleToEnum } from "src/assets/enums/roleEnum";
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: "root"
})
export class LaboratorioGuard implements CanActivate {
    constructor(private router: Router, private storage: Storage) { }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const helper = new JwtHelperService();

        const decodedToken = helper.decodeToken(await this.storage.get('jwt'));
        const role = await this.storage.get('role');
      //  if (role === RoleToEnum.Adm_TAG || role === RoleToEnum.Prod_TAG || role === RoleToEnum.Lab_TAG)
            return true;

        this.router.navigate(["/dashboard"]);
        return false;

    }
}