import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {


  constructor(private router: Router, private storage: Storage) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {  
    var ok = await this.storage.get('jwt'); 
    if (ok) {
      return true;
    }
    else {
      this.router.navigate(["/login"]);
      return false;
    }
  }

}


